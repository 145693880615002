import React, { useState } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween, Tabs, TabsProps } from "@cloudscape-design/components";
import { TAB_ID_LABEL, TAB_ID } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { OverviewHome } from '../programOverview/overviewHome';
import { updateCurrentProgramSetupTab, selectCurrentProgramSetupTab } from '../appLayout/appLayoutSlice';
import { discardAttributesUpdates, selectAttributeModified } from '../attributes/attributesSlice';
import { selectOverviewModified, discardOverviewUpdates } from '../programOverview/programOverviewSlice';
import { discardKeyDatesInfoUpdates, selectKeyDatesModified } from '../keyDates/keyDatesSlice';
import { discardFinancialInfoUpdates, selectFinancialInfoModified } from '../financialInfo/financialInfoSlice';
import { discardMarketInfoUpdates, selecMarketInfoModified } from '../marketInfo/marketInfoSlice';
import { discardSpecsUpdates, selectSpecsModified } from '../specs/specsSlice';
import { discardMFRInfoUpdates, selectMfrInfoModified } from '../manufacturingInfo/manufacturingInfoSlice';
import { Attributes } from '../attributes/attributesHome';
import { KeyDates } from '../keyDates/keyDatesHome';
import { MarketInfo } from '../marketInfo/marketInfo';
import { FinancialInfo } from '../financialInfo/financialInfo';
import { ManufacturingInfo } from '../manufacturingInfo/manufacturingInfo';
import { Specs } from '../specs/specs';
import { AsinProgramMappingTable } from '../asinProgramMapping/asinProgramMappingTable';

export const ProgramSetupTabs = () => {
    const dispatch = useAppDispatch();
    const currentTab = useAppSelector(selectCurrentProgramSetupTab);
    const isOverviewModified = useAppSelector(selectOverviewModified);
    const isAttributeModified = useAppSelector(selectAttributeModified);
    const isKeydatesModified = useAppSelector(selectKeyDatesModified);
    const isFinanceModified = useAppSelector(selectFinancialInfoModified);
    const isMarketModified = useAppSelector(selecMarketInfoModified);
    const isMfrModified = useAppSelector(selectMfrInfoModified);
    const isSpecsModified = useAppSelector(selectSpecsModified);
    const [changedTab, setChangedTab] = useState(TAB_ID.overview);
    const [visible, setVisible] = useState(false);

    const tabIdDiscardMap = {
        [TAB_ID.overview]: discardOverviewUpdates(),
        [TAB_ID.attributes]: discardAttributesUpdates(),
        [TAB_ID.keyDates]: discardKeyDatesInfoUpdates(),
        [TAB_ID.financialInfo]: discardFinancialInfoUpdates(),
        [TAB_ID.marketInfo]: discardMarketInfoUpdates(),
        [TAB_ID.manufacturingInfo]: discardMFRInfoUpdates(),
        [TAB_ID.specs]: discardSpecsUpdates()
    };
    const leavePage = () => {
        const currTab = currentTab ? currentTab : TAB_ID.overview;
        if (tabIdDiscardMap[currTab]) dispatch(tabIdDiscardMap[currTab]);
        dispatch(updateCurrentProgramSetupTab(changedTab));
        setVisible(false);
    };

    const onTabChange = (tabid: string) => {
        const currTab = currentTab ? currentTab : TAB_ID.overview;
        const tabIdModifiedMap = {
            [TAB_ID.overview]: isOverviewModified,
            [TAB_ID.attributes]: isAttributeModified,
            [TAB_ID.keyDates]: isKeydatesModified,
            [TAB_ID.financialInfo]: isFinanceModified,
            [TAB_ID.marketInfo]: isMarketModified,
            [TAB_ID.manufacturingInfo]: isMfrModified,
            [TAB_ID.specs]: isSpecsModified
        };
        if (!tabIdModifiedMap[currTab]) {
            dispatch(updateCurrentProgramSetupTab(tabid));
            if (tabIdDiscardMap[currTab]) dispatch(tabIdDiscardMap[currTab]);
        } else {
            setVisible(true);
            setChangedTab(tabid);
        }
    };

    const loadSetupTabs = () => {
        const tabs: TabsProps.Tab[] = [
            {
                label: TAB_ID_LABEL.overview,
                id: TAB_ID.overview,
                content: <OverviewHome />
            },
            {
                label: TAB_ID_LABEL.attributes,
                id: TAB_ID.attributes,
                content: <Attributes />
            },
            {
                label: TAB_ID_LABEL.keyDates,
                id: TAB_ID.keyDates,
                content: <KeyDates />
            },
            {
                label: TAB_ID_LABEL.financialInfo,
                id: TAB_ID.financialInfo,
                content: <FinancialInfo />
            },
            {
                label: TAB_ID_LABEL.manufacturingInfo,
                id: TAB_ID.manufacturingInfo,
                content: <ManufacturingInfo />
            },
            {
                label: TAB_ID_LABEL.marketInfo,
                id: TAB_ID.marketInfo,
                content: <MarketInfo />
            },
            {
                label: TAB_ID_LABEL.specs,
                id: TAB_ID.specs,
                content: <Specs />
            },
            {
                label: TAB_ID_LABEL.asins,
                id: TAB_ID.asins,
                content: <AsinProgramMappingTable />
            }
        ];
        return (
            <Tabs tabs={tabs}
                ariaLabel="Program-setup"
                activeTabId={currentTab ? currentTab : TAB_ID.overview}
                onChange={({ detail }) => onTabChange(detail.activeTabId)}
            />
        );

    };

    return (
        <>
            {loadSetupTabs()}
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header=""
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setVisible(false)}>
                                Cancel
                            </Button>
                            <Button className="bg-primary" variant="primary" onClick={leavePage}>Leave</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <Alert type="warning">
                    Are you sure that you want to leave the current tab? The changes that you made won't be saved.
                </Alert>
            </Modal>
        </>

    );
};

