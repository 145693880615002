import React from 'react';
import {
    Header,
    HelpPanel,
    Icon,
    Link,
    StatusIndicator
} from '@cloudscape-design/components';

import {
    LandingPageResourceSection
} from 'src/components/AppComponents/landingPageConstants';

export const AsinProgramMappingHelpPanel: React.FC = () => {
    return (
        <HelpPanel
            header={<Header>Asin Program Mapping</Header>}
            footer={
                <div>
                    <h3>Further questions{" "}<Icon name="external" size="inherit" /></h3>
                    <ul>
                        <div><Link href={LandingPageResourceSection.contactLink} target="_blank">Create SIM ticket</Link></div>
                    </ul>
                </div>
            }
        >
            This table shows which ASINs are mapped to specific programs and their attributes.
            <h3>Definitions</h3>
            <dl>
                <dt>
                    ASIN (Amazon Standard Identification Number)
                </dt>
                <dd>
                    The unique product identifier - similar to a SKU
                </dd>
                <dt>
                RPN (Root Program Name)
                </dt>
                <dd>
                The parent program associated with the ASIN
                </dd>
                <dt>
                FPN (Finance Program Name)
                </dt>
                <dd>
                A child program of the RPN
                </dd>
                <dt>
                SKU description
                </dt>
                <dd>
                The attributes associated with the ASIN
                </dd>
            </dl>
            <h3>Key Points</h3>
            <p>Each ASIN maps to only one RPN and one FPN.</p>
            <p>The FPN is determined by comparing the ASIN's attributes to the RPN's attribute rules.</p>
            <h3>Status Indicators Definition</h3>
            <p><StatusIndicator type="error"></StatusIndicator>: This ASIN has been deprecated and is no longer in use.</p>
            <p><StatusIndicator type="warning"></StatusIndicator>: This FPN hasn't been created for this RPN. This occurs when the ASIN's attributes map to an FPN that has NOT been created.</p>
            <h3>Questions?</h3>
            <dl>
                <dt>
                What to do if I see a <StatusIndicator type="warning"></StatusIndicator>?
                </dt>
                <dd>
                Contact the RPN's PM owner or the appropriate team to create this missing FPN. Contact information can be found on the RPN's Overview tab.
                </dd>
                <dt>
                What to do if I see a <StatusIndicator type="error"></StatusIndicator>?
                </dt>
                <dd>
                No action is typically required. These are shown for historical reference.
                </dd>
            </dl>
        </HelpPanel>
    );
};
