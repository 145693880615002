import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'src/components/redux/store';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Table,
    Box,
    Link,
    PropertyFilter,
    Header,
    Pagination
} from '@cloudscape-design/components';
import {
    DEFAULT_PAGE_SIZE,
    propertyFilterI18nStrings
} from 'src/components/common/CloudscapeCommons';
import {
    AsinProgramMapping,
    asinProgramMappingTableColumnDefinitions,
    asinProgramMappingFilteringProperties
} from './asinProgramMappingTableConfig';
import { useGetAsinProgramMappingQuery } from 'src/components/RPT/services/api';
import { selectLocationData } from 'src/components/RPT/features/appLayout/appLayoutSlice';
import { useHelpPanel } from 'src/components/common/HelpPanel/useHelpPanel';
import { AsinProgramMappingHelpPanel } from './asinProgramMappingHelpPanel';
import { useAlertMessage} from 'src/components/common/Contexts/alertMessageContext';
import { ILocationData } from 'src/components/RPT/interfaces/interfaces';

export const AsinProgramMappingTable = () => {
    const { setError } = useAlertMessage();
    const { setHelpPanelContent, setIsOpen } = useHelpPanel();
    const locationData = useSelector((state: RootState) => selectLocationData(state)) as ILocationData; // this is how program and fpn information is currently retrieved
    const { program, fpn } = locationData;

    const rpnId = program?.id ? parseInt(program.id) : undefined;
    const fpnId = fpn?.id ? parseInt(fpn.id) : null;

    const { data, error, isLoading } = useGetAsinProgramMappingQuery(
        { rpnId: rpnId ?? 0, fpnId },
        { skip: !rpnId}
    );

    useEffect(() => {
        if (error) {
            console.log("there was an error");
            let errorMessage = 'An unknown error occurred';
            if (typeof error === 'object' && error !== null) {
                if ('status' in error) {
                    const fetchError = error as FetchBaseQueryError;
                    errorMessage = 'error' in fetchError 
                        ? String(fetchError.error)
                        : JSON.stringify(fetchError.data);
                } else if ('message' in error) {
                    const serializedError = error as SerializedError;
                    errorMessage = serializedError.message || 'An unknown error occurred';
                }
            }
            setError?.(`Error loading ASIN program mapping: ${errorMessage}`);
            console.error('Error in AsinProgramMappingTable:', error);
        }
    }, [error, setError]);

    const openHelpPanel = () => {
        setHelpPanelContent(<AsinProgramMappingHelpPanel />);
        setIsOpen(true);
    };

    const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(data || [], {
        propertyFiltering: {
            filteringProperties: asinProgramMappingFilteringProperties,
            empty: <div>No resources</div>,
            noMatch: <div>No match found.</div>
        },
        pagination: {
            pageSize: DEFAULT_PAGE_SIZE
        },
        sorting: {
            defaultState: {
                sortingColumn: asinProgramMappingTableColumnDefinitions[0],
                isDescending: true
            }
        }
    });

    return (
        <Table<AsinProgramMapping>
            {...collectionProps}
            stickyHeader
            sortingColumn={collectionProps.sortingColumn}
            onSortingChange={collectionProps.onSortingChange}
            columnDefinitions={asinProgramMappingTableColumnDefinitions}
            items={items}
            loading={isLoading}
            loadingText="Loading resources"
            trackBy="asin"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No ASINs mapped to this program.
                    </Box>
                </Box>
            }
            filter={
                <PropertyFilter
                    {...propertyFilterProps}
                    filteringProperties={asinProgramMappingFilteringProperties}
                    filteringPlaceholder='Find items'
                    countText={filteredItemsCount + " matches"}
                    onChange={({ detail }) => actions.setPropertyFiltering(detail) }
                    filteringAriaLabel='Filter'
                    i18nStrings={propertyFilterI18nStrings()}
                />
            }
            header={
                <Header
                    counter={`(${filteredItemsCount})`}
                    info={<Link variant="info" onFollow={openHelpPanel}>Info</Link>}
                >
                    Asin Program Mappings
                </Header>
            }
            pagination={
                <Pagination
                    {...paginationProps}
                />
            }
        />
    );
};