import React, { useState } from 'react';
import { HelpPanelContext } from 'src/components/common/HelpPanel/HelpPanelContext';

export const HelpPanelProvider: React.FC = ({ children }) => {
    const [helpPanelContent, setHelpPanelContent] = useState<React.ReactNode | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <HelpPanelContext.Provider value={{ helpPanelContent, setHelpPanelContent, isOpen, setIsOpen }}>
            {children}
        </HelpPanelContext.Provider>
    );
}