import React, { useEffect, useState } from 'react';
import {
    AppLayout,
    Box,
    ContentLayout,
    Flashbar
} from "@cloudscape-design/components";
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { useAppDispatch } from '../../../redux/hooks';
import { RPTSideNav } from './sideNav';
import { AlertMessageProvider } from '../../../common/Contexts/alertMessageContext';
import { RPTBreadcrumbs } from './breadcrumbs';
import { RPTPageHeader } from './pageHeader';
import { useGetProductHierarchyQuery } from '../../../redux/services/commonApi';
import { useGetAttributeListMasterQuery, useGetAttributeTypeMasterQuery } from '../../../redux/services/attributesApi';
import { useGetUserPolicyDataQuery } from '../../services/apis/userPolicyApi';
import { updateLocationData, updateCurrentProgramSetupTab } from './appLayoutSlice';
import { updateMasterAttrTypeListMap} from '../attributes/attributesSlice';
import AppLayoutHelper from './appLayoutHelper';
import { IApiResponse } from 'src/components/Interfaces/interface';
import { PRM_DASHBOARD_URL, STATUS_CODES, SUPPORT_WIKI_URL } from '../../constants/constants';
import ScreenUtils from '../../utils/screenUtils';
import { IAttributeListResponse, IAttributeTypeResponse } from '../../interfaces/interfaces';
import { TopNav } from 'src/components/common/Header/topNav';
import { appName } from 'src/constants/constants';
import useNotification from 'src/components/Hooks/notifications';
import { APP_LAYOUT_MESSAGES } from '../../constants/displayMessages';
import { updateCurrUserPolicyLoading } from '../userPolicy/userPolicySlice';
import { HelpPanelProvider } from 'src/components/common/HelpPanel/HelpPanelProvider';
import { useHelpPanel } from 'src/components/common/HelpPanel/useHelpPanel';


export function RPTAppLayout() {
    return (
        <>
            <HelpPanelProvider>
                <AlertMessageProvider>
                    <AppLayoutWithHelpPanel />
                </AlertMessageProvider>
            </HelpPanelProvider>
        </>
    );
}

const AppLayoutWithHelpPanel = () => {
    const dispatch = useAppDispatch();
    const { getUserId } = ScreenUtils;
    const username = getUserId();
    const { data: prodHierarchy, isError: prodHierarchyError } = useGetProductHierarchyQuery();
    const { data: userPolicyData, isError: userPolicyError, isLoading: policyLoading, isFetching: policyFetching } = useGetUserPolicyDataQuery({ userName: username });
    const { generateLoactionData } = AppLayoutHelper();
    const [hideSideNav] = useState<boolean>(false);
    const [navigationOpen, setNavigationOpen] = useState<boolean>(true);
    const location = useLocation();
    const [sideNavError, setSideNavError] = useState(false);
    const { helpPanelContent, isOpen, setIsOpen } = useHelpPanel();
    const [policyError, setPolicyError] = useState(false);
    const [queryParameters] = useSearchParams();
    const { data: attrListData } = useGetAttributeListMasterQuery();
    const { data: attrTypeData } = useGetAttributeTypeMasterQuery();
    const { notificationItems, addNotificationItem, clearNotifications } = useNotification();


    useEffect(() => {
        const locData = generateLoactionData(queryParameters, location.pathname);
        dispatch(updateLocationData(locData));
        if (!locData.program.id) dispatch(updateCurrentProgramSetupTab(''));
    }, [location.pathname, queryParameters]);

    // This fix needs to be added on main home page but currently facing issues so adding for PRM & AGM seperately
    useEffect(() => {
        if (!username.length) window.location.reload();
    }, [username]);

    useEffect(() => {
        dispatch(updateCurrUserPolicyLoading(policyLoading || policyFetching));
    }, [policyLoading, policyFetching]);

    useEffect(() => {
        if (attrTypeData && attrListData) {
            const typeToListMap: Record<string, string[]> = {};
            attrTypeData.forEach((obj: IAttributeTypeResponse) => typeToListMap[obj.attribute_type] = []);
            attrListData.forEach((obj: IAttributeListResponse) => {
                const attrType = obj.attribute_type;
                if (typeToListMap[attrType]) typeToListMap[attrType].push(obj.attribute_value);
            });
            Object.entries(typeToListMap).forEach(([key, values]) => typeToListMap[key] = values.sort());
            dispatch(updateMasterAttrTypeListMap(typeToListMap));
        }
    }, [attrTypeData, attrListData]);

    useEffect(() => {
        if (userPolicyData) {
            if (userPolicyData.data?.get_user_access) {
                const { statusCode }: IApiResponse = userPolicyData.data?.get_user_access;
                statusCode === STATUS_CODES.success ? setPolicyError(false) : setPolicyError(true);
            }
            if (userPolicyData.errors || userPolicyError) setPolicyError(true);
        }
    }, [userPolicyData, userPolicyError]);

    useEffect(() => {
        if (prodHierarchy) {
            if (prodHierarchy.data?.get_nav_bar) {
                const { statusCode }: IApiResponse = prodHierarchy.data?.get_nav_bar;
                statusCode === STATUS_CODES.success ? setSideNavError(false) : setSideNavError(true);
            }
            if (prodHierarchy.errors || prodHierarchyError) setSideNavError(true);
        }
    }, [prodHierarchy, prodHierarchyError]);

    useEffect(() => {
        clearNotifications();
        if (policyError) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: APP_LAYOUT_MESSAGES.userPolicyError,
                id: "POLICY_ERROR"
            });
        }
        if (sideNavError) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: APP_LAYOUT_MESSAGES.progLoadingError,
                id: "SIDE_NAV_ERROR"
            });
        }
    }, [policyError, sideNavError]);

    return (
        <>
            <TopNav
                appName={appName}
                NavUtilities={[
                    {
                        type: "button",
                        text: "Support",
                        href: SUPPORT_WIKI_URL,
                        external: true
                    },
                    {
                        type: "button",
                        text: "PRM Dashboard",
                        href: PRM_DASHBOARD_URL,
                        external: true
                    }
                ]}
            />
            <AppLayout
                id="rptAppLayout"
                breadcrumbs={<RPTBreadcrumbs />}
                content={
                    <ContentLayout
                        disableOverlap
                        header={<RPTPageHeader />}
                    >
                        <Box margin={{ top: "l" }}>
                            <Outlet />
                        </Box>
                    </ContentLayout>
                }
                navigation={<RPTSideNav hasError={sideNavError} policyLoading={policyFetching || policyLoading} />}
                navigationHide={hideSideNav}
                navigationOpen={navigationOpen}
                notifications={<Flashbar items={notificationItems} stackItems/>}
                onNavigationChange={() => setNavigationOpen(!navigationOpen)}
                toolsHide={!helpPanelContent}
                tools={helpPanelContent}
                toolsOpen={isOpen}
                onToolsChange={({ detail }) => setIsOpen(detail.open)}
                contentType="default"
            />
        </>
    );
};