import React, { useState, useEffect } from 'react';
import { Box, Button, ColumnLayout, Container, Header, Link, SpaceBetween } from "@cloudscape-design/components";
import { IDynamicFieldProps } from '../../../common/DynamicForm/Interfaces';
import DynamicForm from '../../../common/DynamicForm/DynamicForm';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { PROGRAM_OVERVIEW, IFieldMetaData } from '../../constants/fieldData';
import { updateOverview, selectOverview, selectVerityData, selectUserRole } from '../programOverview/programOverviewSlice';
import ScreenUtils from '../../utils/screenUtils';
import { selectLocationData, selectAllRPNs, selectIsLocked } from '../appLayout/appLayoutSlice';
import { SEPERATOR, SK_MAPPING, TAB_ID_LABEL, API_RESPONSE_FIELDS, USER_ACTION_ROLES } from '../../constants/constants';
import { UpdateProgramModal } from './updateProgramModal';
import { CreateProgramPPLS } from './createProgramPPLS';
import { useHelpPanel } from 'src/components/common/HelpPanel/useHelpPanel';


export const ProgramOverview = () => {
    const dispatch = useAppDispatch();
    const programOverview = useAppSelector(selectOverview);
    const programData = useAppSelector(selectVerityData);
    const aliases = programData.aliases.join(', ');
    const [fieldMetadata, setFieldMetadata] = useState<Record<string, IFieldMetaData>>(PROGRAM_OVERVIEW);
    const [dynamicFormInput, setDynamicFormInput] = useState<IDynamicFieldProps[][]>();
    const locationData = useAppSelector(selectLocationData);
    const allRPNs = useAppSelector(selectAllRPNs);
    const [updateModal, setUpdateModal] = useState(false);
    const [pplsModal, setPplsModal] = useState(false);
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole));
    const isLocked = useAppSelector(selectIsLocked);
    const { setHelpPanelContent, setIsOpen } = useHelpPanel();


    const updateState = (fieldValue: any, fieldName: string) => {
        const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
        const SK = TAB_ID_LABEL.overview + SEPERATOR + SK_MAPPING.overview + SEPERATOR + fieldName;
        const value = fieldValue.value ?? fieldValue;
        dispatch(updateOverview(ScreenUtils.updateClientState(value, fieldName, API_RESPONSE_FIELDS.itemValues, programOverview, PK, SK)));
    };

    const openHelpPanel = () => {
        setHelpPanelContent(<div>Help Panel Content</div>);
        setIsOpen(true);
    };

    useEffect(() => {
        if (programOverview && fieldMetadata) {
            const fMetaData: Record<string, IFieldMetaData> = { ...fieldMetadata };
            Object.entries(fieldMetadata).forEach(([field]) => {
                fMetaData[field].actions = updateState;
                fMetaData[field].readonly = locationData.fpn.id ? true : !hasWriteAccess;
            });
            const overview = { ...programOverview };

            if (overview['Program predeccessor']?.ItemValues) {
                const selectedVal = overview['Program predeccessor'].ItemValues;
                overview['Program predeccessor'] = { ...overview['Program predeccessor'], ItemValues: { label: allRPNs[selectedVal], value: selectedVal } };
            }
            setDynamicFormInput(ScreenUtils.generateDynamicFormInput(overview, fMetaData));
        }
    }, [programOverview, fieldMetadata, locationData, hasWriteAccess]);

    useEffect(() => {
        if (allRPNs) {
            const rpnList: Record<string, string> = { "<1st Generation>": "<1st Generation>", ...allRPNs };
            delete rpnList[locationData.program.id];
            const rpnOptions = ScreenUtils.generateOptionsFromIdLabel(rpnList);
            const fMetaData: Record<string, IFieldMetaData> = { ...fieldMetadata };
            fMetaData['Program predeccessor'].options = rpnOptions;
            setFieldMetadata(fMetaData);
        }
    }, [allRPNs]);

    const loadOverviewEdit = () => {
        if (dynamicFormInput) {
            return (
                <>
                    <SpaceBetween className='mg-top-md' size="xl">
                        {!locationData.fpn.id ? <ColumnLayout columns={4} variant="text-grid">

                            <div>
                                <div>
                                    <Box variant="awsui-key-label">Program Name</Box>
                                    <div>{locationData.program.name}</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Box variant="awsui-key-label">Aliases</Box>
                                    <div>{aliases}</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Box variant="awsui-key-label">Product line</Box>
                                    <div>{locationData.productLine.name}</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Box variant="awsui-key-label">Product code</Box>
                                    <div>{programData.productCode}</div>
                                </div>
                            </div>

                        </ColumnLayout> :
                            <ColumnLayout columns={4} variant="text-grid">
                                <div>
                                    <div>
                                        <Box variant="awsui-key-label">FPN Name</Box>
                                        <div>{locationData.fpn.name}</div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Box variant="awsui-key-label">Aliases</Box>
                                        <div>{aliases}</div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Box variant="awsui-key-label">Program Name</Box>
                                        <div>{locationData.program.name}</div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Box variant="awsui-key-label">Product line</Box>
                                        <div>{locationData.productLine.name}</div>
                                    </div>
                                </div>

                            </ColumnLayout>
                        }
                        <DynamicForm formInput={dynamicFormInput} />
                    </SpaceBetween>
                </>

            );
        } else {
            return "No data to display.";
        }
    };

    return (
        <>
            <Container
                header={
                    <Header variant="h2"
                        actions={!locationData.fpn.id &&
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button disabled={!hasWriteAccess || isLocked} onClick={() => setUpdateModal(true)}>Change program name/status</Button>
                                {/*  Comment this until confirmed {!programData.ppls_id?.length && <Button disabled={!hasWriteAccess} onClick={() => setPplsModal(true)}>Create Program in PPLS</Button>} */}
                            </SpaceBetween>
                        }
                        info={<Link variant="info" onFollow={openHelpPanel}>Info</Link>}
                    >
                        Program Overview
                    </Header>
                }
            >  {loadOverviewEdit()}
                <UpdateProgramModal setVisibile={setUpdateModal} visible={updateModal} />
                {/* <CreateProgramPPLS setVisible={setPplsModal} visible={pplsModal}/>  Comment this until confirmed */}
            </Container>
        </>
    );
};
