/**
 * Formats a value for display in a table cell, providing a default value for empty, null, or undefined inputs.
 * This function is particularly useful for Cloudscape Design Library table components to ensure consistent
 * display of data, especially when dealing with potentially missing or empty values.
 *
 * @param {any} value - The value to be formatted. Can be of any type.
 * @param {string} [defaultValue='-'] - The default value to return if the input is empty, null, or undefined.
 *                                      Defaults to '-' if not specified.
 * @returns {string} The formatted value as a string. Returns the defaultValue if the input is empty, null, or undefined.
 *
 * @example
 * // Returns '-'
 * formatEmptyValueForTable(null);
 * formatEmptyValueForTable(undefined);
 * formatEmptyValueForTable('');
 *
 * @example
 * // Returns 'N/A'
 * formatEmptyValueForTable(null, 'N/A');
 *
 * @example
 * // Returns '42'
 * formatEmptyValueForTable(42);
 *
 * @example
 * // Usage in Cloudscape table definition
 * {
 *   id: 'columnId',
 *   header: 'Column Header',
 *   cell: item => formatEmptyValueForTable(item.someProperty)
 * }
 */
export const formatEmptyValueForTable = (value: any, defaultValue: string = '-'): string => {
    if (value === null || value === undefined || value === '') {
        return defaultValue;
    }
    return String(value);
};
