import React from 'react';
import {
    Link,
    PropertyFilterProps,
    StatusIndicator,
    TableProps
} from "@cloudscape-design/components";
import {
    propertyFilteringOperators
} from 'src/components/common/CloudscapeCommons';
import { formatEmptyValueForTable } from 'src/components/common/Utils/tableUtils';
import { townsendPartNumberLink } from '../../constants/constants';

export interface AsinProgramMapping {
    asin: string;
    part_number: string;
    sku_type: string;
    sku_description: string;
    fpn_id: number | null;
    fpn: string;
    is_deprecated: boolean;
    created_ts: string;
    created_by: string;
}

export const asinProgramMappingTableColumnDefinitions: TableProps.ColumnDefinition<AsinProgramMapping>[] = [
    {
        id: 'asin',
        header: 'ASIN',
        cell: item => (
            <StatusIndicator type={item.asin.endsWith('ABC') ? 'error' : 'success'}> {item.asin}</StatusIndicator>
        ),
        sortingField: 'asin'
    },
    {
        id: 'part_number',
        header: 'Part number',
        cell: item => (
            <Link external href={`${townsendPartNumberLink.domain}${item.part_number}${townsendPartNumberLink.queryParam}`} target="_blank"> {item.part_number}</Link>
        ),
        sortingField: 'part_number'
    },
    {
        id: 'sku_type',
        header: 'SKU type',
        cell: item => item.sku_type,
        sortingField: 'sku_type'
    },
    {
        id: 'sku_description',
        header: 'SKU description',
        cell: item => formatEmptyValueForTable(item.sku_description),
        sortingField: 'sku_description'
    },
    {
        id: 'fpn_id',
        header: 'FPN ID',
        cell: item => (formatEmptyValueForTable(item.fpn_id)),
        sortingField: 'fpn_id'
    },
    {
        id: 'fpn',
        header: 'FPN',
        cell: item => (
            <StatusIndicator type={item.fpn_id === null ? 'warning' : 'success'}> {item.fpn}</StatusIndicator>
        ),
        sortingField: 'fpn'
    },
    {
        id: 'created_ts',
        header: 'Created timestamp',
        cell: item => (
            formatEmptyValueForTable(item.created_ts)
        ),
        sortingField: 'created_ts'
    },
    {
        id: 'created_by',
        header: 'Created by',
        cell: item => (formatEmptyValueForTable(item.created_by)),
        sortingField: 'created_by'
    }
];

export const asinProgramMappingFilteringProperties: PropertyFilterProps.FilteringProperty[] = [
    {
        key: 'asin',
        operators: propertyFilteringOperators.textFilters,
        propertyLabel: 'ASIN',
        groupValuesLabel: 'ASIN values'
    },
    {
        key: 'part_number',
        operators: propertyFilteringOperators.textFilters,
        propertyLabel: 'Part number',
        groupValuesLabel: 'Part number values'
    },
    {
        key: 'sku_type',
        operators: propertyFilteringOperators.textFilters,
        propertyLabel: 'SKU type',
        groupValuesLabel: 'SKU type values'
    },
    {
        key: 'sku_description',
        operators: propertyFilteringOperators.textFilters,
        propertyLabel: 'SKU description',
        groupValuesLabel: 'SKU description values'
    },
    {
        key: 'fpn_id',
        operators: propertyFilteringOperators.textFilters,
        propertyLabel: 'FPN ID',
        groupValuesLabel: 'FPN IDs'
    },
    {
        key: 'fpn',
        operators: propertyFilteringOperators.textFilters,
        propertyLabel: 'FPN',
        groupValuesLabel: 'FPN values'
    },
    {
        key: 'created_ts',
        operators: propertyFilteringOperators.timestampFilters,
        propertyLabel: 'Created ts',
        groupValuesLabel: 'Created values'
    },
    {
        key: 'created_by',
        operators: propertyFilteringOperators.textFilters,
        propertyLabel: 'Created by',
        groupValuesLabel: 'Created By values'
    }
];