import {
    CollectionPreferencesProps,
    PropertyFilterProps
} from '@cloudscape-design/components';

export const BLANK_SEARCH_AND = { tokens: [], operation: 'and' } as PropertyFilterProps.Query;

export const DEFAULT_PAGE_SIZE = 50;

export const defaultPreferences: CollectionPreferencesProps.Preferences = {
    pageSize: DEFAULT_PAGE_SIZE,
    wrapLines: false,
    stripedRows: false,
    contentDensity: 'compact'
};

export const propertyFilteringOperators = {
    timestampFilters: [':', '!:', '=', '!=', '<', '<=', '>', '>='],
    textFilters: [':', '!:', '=', '!=']
}

export const propertyFilterI18nStrings: () => PropertyFilterProps.I18nStrings = () => ({
    filteringAriaLabel: 'your choice',
    dismissAriaLabel: 'Dismiss',
    clearAriaLabel: 'Clear',
  
    filteringPlaceholder: `Filter by text, property or value`,
    groupValuesText: 'Values',
    groupPropertiesText: 'Tag',
    operatorsText: 'Operators',
  
    operationAndText: 'and',
    operationOrText: 'or',
  
    operatorLessText: 'Less than',
    operatorLessOrEqualText: 'Less than or equal',
    operatorGreaterText: 'Greater than',
    operatorGreaterOrEqualText: 'Greater than or equal',
    operatorContainsText: 'Contains',
    operatorDoesNotContainText: 'Does not contain',
    operatorEqualsText: 'Equals',
    operatorDoesNotEqualText: 'Does not equal',
  
    editTokenHeader: 'Edit filter',
    propertyText: 'Property',
    operatorText: 'Operator',
    valueText: 'Value',
    cancelActionText: 'Cancel',
    applyActionText: 'Apply',
    allPropertiesLabel: 'All properties',
  
    tokenLimitShowMore: 'Show more',
    tokenLimitShowFewer: 'Show fewer',
    clearFiltersText: 'Clear filters',
    removeTokenButtonAriaLabel: (token: any) => `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
    enteredTextLabel: (text: any) => `Use: "${text}"`
});